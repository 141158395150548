import { Routes } from '@angular/router';
import { NoContentComponent } from '@modules/home/pages/no-content/no-content.component';

const domainRoutes: { [key: string]: Routes } = {
  halloverden: [
    {
      path: '',
      loadChildren: () =>
        import('./modules/home/home.routes').then((m) => m.routes),
    },
    {
      path: '**',
      component: NoContentComponent,
    },
  ],
  'blog.halloverden': [
    {
      path: '**',
      component: NoContentComponent,
    },
  ],
  'bokami.halloverden': [
    {
      path: '',
      loadChildren: () =>
        import('./modules/handbook/handbook.routes').then((m) => m.routes),
    },
    {
      path: '**',
      component: NoContentComponent,
    },
  ],
  'internkontroll.halloverden': [
    {
      path: '',
      loadChildren: () =>
        import('./modules/internal-control/internal-control.routes').then(
          (m) => m.routes,
        ),
    },
    {
      path: '**',
      component: NoContentComponent,
    },
  ],
};

export const routes =
  domainRoutes[
    window.location.hostname.substring(
      0,
      window.location.hostname.lastIndexOf('.'),
    )
  ];
