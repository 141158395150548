import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { IMAGE_LOADER, ImageLoaderConfig } from '@angular/common';
import { environment } from '@environments/environment';

export function provideImageLoaderConfig(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: IMAGE_LOADER,
      useValue: (config: ImageLoaderConfig) => {
        let url = config.src.includes('https://') ? `${config.src}` : `${environment.directus.host}${environment.directus.assetsPath}/${config.src}`;
        return config.width ? `${url}?width=${config.width}` : `${url}`;
      },
    },
  ]);
}
