import {
  AfterViewInit,
  Component,
  HostListener,
  OnInit,
  Renderer2,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BodyHelper } from '@core/helpers/body.helper';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnInit {
  constructor(private _renderer: Renderer2) {}

  private static _toggleBodyClass(keyboardNavigation: boolean): void {
    if (keyboardNavigation) {
      BodyHelper.addClass('keyboard-navigation');
    } else {
      BodyHelper.removeClass('keyboard-navigation');
    }
  }

  ngAfterViewInit(): void {
    this._renderer.listen('body', 'click', () => {
      AppComponent._toggleBodyClass(false);
    });
  }

  ngOnInit(): void {
    // To prevent transition on page load
    // Class is removed in ThemeService
    BodyHelper.addClass('transition-prevention');
  }

  @HostListener('keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    AppComponent._toggleBodyClass(true);
  }
}
