import { ContentHandlerInterface } from '@core/content-handlers/content-handler.interface';
import { Content } from '@models/content/content';
import { BulletList } from '@models/content/bullet-list';
import { Heading } from '@models/content/heading';
import { Paragraph } from '@models/content/paragraph';
import { Text } from '@models/content/text';
import { ListItem } from '@models/content/list-item';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { MarkHandlerInterface } from '@core/content-handlers/mark-handler.interface';
import { DomBuilderService } from '@core/services/dom-builder.service';

export const defaultContentHandlerFactory = (
  rendererFactory: RendererFactory2,
) => {
  return new DefaultContentHandler(rendererFactory);
};

@Injectable({
  providedIn: 'root',
})
export class DefaultContentHandler implements ContentHandlerInterface {
  private supportedContentTypes = [
    'bulletList',
    'heading',
    'paragraph',
    'relation-block',
    'text',
  ];

  private _renderer: Renderer2;


  constructor(
    _rendererFactory: RendererFactory2,
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  handle(content: Content, contentHandlers: ContentHandlerInterface[], markHandlers: MarkHandlerInterface[]): any {
    switch (content.type) {
      case 'bulletList':
        return this.makeBulletList(content as BulletList, contentHandlers, markHandlers);
      case 'heading':
        return this.makeHeading(content as Heading, contentHandlers, markHandlers);
      case 'paragraph':
        return this.makeParagraph(content as Paragraph, contentHandlers, markHandlers);
      case 'text':
        return this.makeText(content as Text, markHandlers);
      default:
        return this.getSupportedContentHandler(content.type, contentHandlers).handle(content, contentHandlers, markHandlers);
    }
  }

  supports(type: string): boolean {
    return this.supportedContentTypes.includes(type);
  }

  getPriority(): number {
    return 0;
  }

  makeBulletList(bulletList: BulletList, contentHandlers: ContentHandlerInterface[], markHandlers: MarkHandlerInterface[]): HTMLUListElement {
    const el = this._renderer.createElement(`ul`);
    bulletList.content.forEach((c) => {
      this._renderer.appendChild(el, this.makeListItem(c, contentHandlers, markHandlers));
    });
    return el;
  }

  makeListItem(listItem: ListItem, contentHandlers: ContentHandlerInterface[], markHandlers: MarkHandlerInterface[]): HTMLLIElement {
    const el = this._renderer.createElement(`li`);
    listItem.content.forEach((c) => {
      this._renderer.appendChild(el, this.handle(c, contentHandlers, markHandlers));
    });
    return el;
  }

  makeHeading(heading: Heading, contentHandlers: ContentHandlerInterface[], markHandlers: MarkHandlerInterface[]): HTMLHeadingElement {
    const el = this._renderer.createElement(`h${heading.attrs.level}`);
    heading.content.forEach((c) => {
      const tc = this.handle(c, contentHandlers, markHandlers);
      if (tc instanceof HTMLElement) {
        this._renderer.appendChild(el, tc);
      } else {
        el.innerHTML += tc;
      }
    });
    return el;
  }

  makeParagraph(paragraph: Paragraph, contentHandlers: ContentHandlerInterface[], markHandlers: MarkHandlerInterface[]): HTMLParagraphElement {
    const el = this._renderer.createElement(`p`);
    paragraph.content?.forEach((c) => {
      const tc = this.handle(c, contentHandlers, markHandlers);
      if (tc instanceof HTMLElement) {
        this._renderer.appendChild(el, tc);
      } else {
        el.innerHTML += tc;
      }
    });
    return el;
  }

  makeText(text: Text, markHandlers: MarkHandlerInterface[]): any {
    if (text.marks) {
      return this.handleMarks(text.text, text.marks, markHandlers);
    }

    return text.text;
  }

  handleMarks(text: string, marks: any[], markHandlers: MarkHandlerInterface[]) {
    let r: any = text;
    marks.forEach((mark: any) => {
      const mh = DomBuilderService.getSupportedMarkHandler(mark.type, markHandlers);
      if (null === mh) {
        return;
      }

      r = mh.handle(r, mark);
    });
    return r;
  }

  getSupportedContentHandler(type: string, contentHandlers: ContentHandlerInterface[]): ContentHandlerInterface {
    let ch = null;

    contentHandlers.forEach((h) => {
      if (h.supports(type)) {
        ch = h;
      }
    });

    if (null === ch) {
      throw new Error(`No content handler was found for type ${type}`);
    }

    return ch;
  }
}
