import { EnvironmentProviders } from '@angular/core';
import { environment } from '@environments/environment';
import {
  provideRouter,
  withComponentInputBinding,
  withDebugTracing,
} from '@angular/router';
import { routes } from '../app.routes';

export function provideRouterWithConfig(): EnvironmentProviders {
  if (environment.router.debug) {
    return provideRouter(
      routes,
      withComponentInputBinding(),
      withDebugTracing()
    );
  }
  return provideRouter(routes, withComponentInputBinding());
}
