import { EnvironmentProviders, Injector, makeEnvironmentProviders, RendererFactory2 } from '@angular/core';
import {
  salaryTableReferenceContentHandlerFactory,
} from '@core/content-handlers/salary-table-reference.content-handler';
import { CONTENT_HANDLERS_INJECTION_TOKEN } from '@core/injection-tokens/content-handlers.injection-token';
import { headingContentHandlerFactory } from '@core/content-handlers/heading.content-handler';
import { defaultContentHandlerFactory } from '@core/content-handlers/default.content-handler';
import { MARK_HANDLERS_INJECTION_TOKEN } from '@core/injection-tokens/mark-handlers.injection-token';
import { defaultMarkHandlerFactory } from '@core/content-handlers/default.mark-handler';
import { ImageWithAltTextContentHandler } from '@core/content-handlers/image-with-alt-text.content-handler';

export function provideContentHandlers(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: CONTENT_HANDLERS_INJECTION_TOKEN,
      multi: true,
      useFactory: defaultContentHandlerFactory,
      deps: [
        RendererFactory2,
      ],
    },
    {
      provide: CONTENT_HANDLERS_INJECTION_TOKEN,
      multi: true,
      useClass: ImageWithAltTextContentHandler,
    },
    {
      provide: CONTENT_HANDLERS_INJECTION_TOKEN,
      multi: true,
      useFactory: salaryTableReferenceContentHandlerFactory,
      deps: [
        Injector,
        RendererFactory2,
      ],
    },
    {
      provide: CONTENT_HANDLERS_INJECTION_TOKEN,
      multi: true,
      useFactory: headingContentHandlerFactory,
      deps: [
        RendererFactory2,
      ],
    },
  ]);
}

export function provideMarkHandlers(): EnvironmentProviders {
  return makeEnvironmentProviders([
    {
      provide: MARK_HANDLERS_INJECTION_TOKEN,
      multi: true,
      useFactory: defaultMarkHandlerFactory,
      deps: [
        RendererFactory2,
      ],
    },
  ]);
}
