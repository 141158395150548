import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/config/app.config';
import { AppComponent } from './app/app.component';
import { enableProdMode } from '@angular/core';
import { EnvironmentHelper } from '@core/helpers/environment.helper';

if (EnvironmentHelper.isProduction()) {
  enableProdMode();
}
bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
