import { ContentHandlerInterface } from '@core/content-handlers/content-handler.interface';
import { ImageWithAltText } from '@models/image/image-with-alt-text';
import { Injectable } from '@angular/core';
import {
  ImageWithAltTextComponent,
} from '@shared/components/content/image-with-alt-text/image-with-alt-text.component';
import { DynamicContentInterface } from '@shared/components/content/dynamic-container/dynamic-content.interface';

@Injectable({
  providedIn: 'root',
})
export class ImageWithAltTextContentHandler implements ContentHandlerInterface {
  private supportedContentTypes = [
    'image_with_alt_text',
  ];

  handle(content: any): any {
    return this.makeImageWithAltText(content as ImageWithAltText);
  }

  supports(type: string): boolean {
    return this.supportedContentTypes.includes(type);
  }

  getPriority(): number {
    return 0;
  }

  makeImageWithAltText(imageWithAltText: ImageWithAltText): DynamicContentInterface {
    return {
      component: {
        type: ImageWithAltTextComponent,
        inputs: {
          src: imageWithAltText.image.src,
          altText: imageWithAltText.altText || '',
          srcset: '350w, 392w, 536w, 576w, 710w',
          sizes: `
            (min-width: 1300px) 710px,
            (min-width: 1024px) 536px,
            (min-width: 768px) 392px,
            (min-width: 411px) 576px,
            350px
          `,
          height: imageWithAltText.image.height || 1,
          width: imageWithAltText.image.width || 1,
        },
      },
    };
  }
}
