import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-image-with-alt-text',
  standalone: true,
  imports: [
    NgOptimizedImage,
  ],
  templateUrl: './image-with-alt-text.component.html',
})
export class ImageWithAltTextComponent {
  @Input({ required: true })
  src: string;

  @Input({ required: true })
  altText: string;

  @Input({ required: false })
  class: string = '';

  @Input({ required: true })
  srcset: string;

  @Input({ required: true })
  sizes: string;

  @Input({ required: true })
  height: number;

  @Input({ required: true })
  width: number;
}
