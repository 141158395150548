import { ContentHandlerInterface } from '@core/content-handlers/content-handler.interface';
import { Inject, Injector, LOCALE_ID, Renderer2, RendererFactory2 } from '@angular/core';
import { SalaryTableReference } from '@models/salary-table/salary-table-reference';
import { formatNumber } from '@angular/common';

export const salaryTableReferenceContentHandlerFactory = (
  injector: Injector,
  rendererFactory: RendererFactory2,
) => {
  return new SalaryTableReferenceContentHandler(
    injector.get(LOCALE_ID),
    rendererFactory
  );
};

type TableData = {
  heading: RowData,
  body: RowData[]
};

type RowData = string[];

export class SalaryTableReferenceContentHandler implements ContentHandlerInterface {
  private supportedContentTypes = [
    'salary_table_reference',
  ];

  private _renderer: Renderer2;

  constructor(
    @Inject(LOCALE_ID)
    private locale: string,
    rendererFactory: RendererFactory2,
  ) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  handle(content: SalaryTableReference): any {
    return this.createContainerElement(this.structureData(content));
  }

  supports(type: string): boolean {
    return this.supportedContentTypes.includes(type);
  }

  getPriority(): number {
    return 0;
  }

  structureData(content: SalaryTableReference): TableData {
    const data: TableData = {
      heading: [
        'Eksamensår',
        'Årslønn bachelor',
        'Årslønn master',
      ],
      body: [],
    };

    content.salaryTable?.salaryTableRows.sort((strA, strB) => {
      if (strA.examYear === strB.examYear) {
        return 0;
      }

      return strA.examYear > strB.examYear ? -1 : 1;
    }).forEach((str) => {
      const formattedBa = formatNumber(
        str.salaryBachelor,
        this.locale,
        '1.0-0',
      ) + ' kr';

      const formattedMa = formatNumber(
        str.salaryMaster,
        this.locale,
        '1.0-0',
      ) + ' kr';

      data.body.push([str.examYear, formattedBa, formattedMa]);
    });

    return data;
  }

  createContainerElement(tableData: TableData): HTMLDivElement {
    const el = this._renderer.createElement('div');
    this._renderer.setAttribute(el, 'class', 'salary-table');
    this._renderer.appendChild(el, this.createTableElement(tableData));
    return el;
  }

  createTableElement(tableData: TableData): HTMLTableElement {
    const tel = this._renderer.createElement('table');
    this._renderer.appendChild(tel, this.createTableHeading(tableData.heading));
    this._renderer.appendChild(tel, this.createTableBody(tableData.body));
    return tel;
  }

  createTableHeading(row: RowData): HTMLTableSectionElement {
    const hel = this._renderer.createElement('thead');
    this._renderer.appendChild(hel, this.createTableRow(row, true));
    return hel;
  }

  createTableBody(rows: RowData[]): HTMLTableSectionElement {
    const bel = this._renderer.createElement('tbody');
    rows.forEach((row) => {
      this._renderer.appendChild(bel, this.createTableRow(row));
    });
    return bel;
  }

  createTableRow(row: RowData, isHeading: boolean = false): HTMLTableRowElement {
    const rel = this._renderer.createElement('tr');
    row.forEach((val) => {
      this._renderer.appendChild(rel, this.createTableCell(val, isHeading));
    });
    return rel;
  }

  createTableCell(content: string, isHeading: boolean = false): HTMLTableCellElement {
    const elementName = isHeading ? 'th' : 'td';
    const cel = this._renderer.createElement(elementName);
    cel.innerText = content;
    return cel;
  }
}
