import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import { provideRouterWithConfig } from './router.config';
import { ThemeService } from '@core/services/theme.service';
import { DARK_MODE_OPTIONS } from '@core/constants/dark-mode-options.constant';
import { DarkModeOptions } from '@interfaces/dark-mode-options.interface';
import { provideHttpClient } from '@angular/common/http';
import { provideGraphql } from './graphql.provider';
import { provideContentHandlers, provideMarkHandlers } from './content-handler.config';
import { provideImageLoaderConfig } from './image-loader.config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouterWithConfig(),
    provideHttpClient(),
    provideGraphql(),
    provideContentHandlers(),
    provideImageLoaderConfig(),
    provideMarkHandlers(),
    {
      provide: APP_INITIALIZER,
      useFactory: (themeService: ThemeService) => {
        return (): Promise<any> => {
          return themeService.serviceInit();
        };
      },
      deps: [ThemeService],
      multi: true,
    },
    {
      provide: DARK_MODE_OPTIONS,
      useValue: {
        darkModeClass: 'theme-dark',
        lightModeClass: 'theme-light',
        preloadingClass: 'theme-preloading',
        storageKey: 'color-theme',
        element: document.body,
      } as DarkModeOptions,
    },
  ],
};
