import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { EnvironmentProviders, inject, makeEnvironmentProviders } from '@angular/core';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { environment } from '@environments/environment';

export function apolloOptionsFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  return {
    link: httpLink.create({
      uri: environment.directus.host + environment.directus.graphqlEndpoint,
    }),
    cache: new InMemoryCache(),
  };
}

export function provideGraphql(): EnvironmentProviders {
  return makeEnvironmentProviders([
    Apollo,
    {
      provide: APOLLO_OPTIONS,
      useFactory: apolloOptionsFactory,
    },
  ]);
}
