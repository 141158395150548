import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LinkMark } from '@models/content/marks/link-mark';
import { MarkHandlerInterface } from '@core/content-handlers/mark-handler.interface';
import { mark } from '@angular/compiler-cli/src/ngtsc/perf/src/clock';
import { ContentHandlerInterface } from '@core/content-handlers/content-handler.interface';
import { DefaultContentHandler } from '@core/content-handlers/default.content-handler';

export const defaultMarkHandlerFactory = (
  rendererFactory: RendererFactory2,
) => {
  return new DefaultMarkHandler(rendererFactory);
};

@Injectable({
  providedIn: 'root',
})
export class DefaultMarkHandler implements MarkHandlerInterface {
  private supportedMarks = [
    'link',
  ];

  private _renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this._renderer = rendererFactory.createRenderer(null, null);
  }

  handle(content: any, mark: any): any {
    switch (mark.type) {
      case 'link':
        return this.handleLinkMark(content as string, mark as LinkMark);
      default:
        return;
    }
  }

  supports(type: string): boolean {
    return this.supportedMarks.includes(type);
  }

  getPriority(): number {
    return 0;
  }

  handleLinkMark(text: string, mark: LinkMark): HTMLAnchorElement {
    const el = this._renderer.createElement('a');
    el.setAttribute('href', mark.attrs.href);
    el.setAttribute('target', mark.attrs.target);
    el.setAttribute('rel', mark.attrs.rel);
    el.innerText = text;
    return el;
  }
}
