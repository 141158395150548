import { ContentHandlerInterface } from '@core/content-handlers/content-handler.interface';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Heading } from '@models/content/heading';
import { MarkHandlerInterface } from '@core/content-handlers/mark-handler.interface';
import slugify from 'slugify';
import { DomBuilderService } from '@core/services/dom-builder.service';


export const headingContentHandlerFactory = (
  rendererFactory: RendererFactory2,
) => {
  return new HeadingContentHandler(rendererFactory);
};

@Injectable({
  providedIn: 'root',
})
export class HeadingContentHandler implements ContentHandlerInterface {
  private supportedContentTypes = [
    'heading',
  ];

  // https://www.npmjs.com/package/slugify
  private slugifyOptions = {
    replacement: '-',
    lower: true,
    strict: true,
    locale: 'nb',
  };

  private _renderer: Renderer2;


  constructor(
    _rendererFactory: RendererFactory2,
  ) {
    this._renderer = _rendererFactory.createRenderer(null, null);
  }

  handle(content: any, contentHandlers: ContentHandlerInterface[], markHandlers: MarkHandlerInterface[]): any {
    switch (content.type) {
      case 'heading':
        return this.makeHeading(content as Heading, contentHandlers, markHandlers);
      default:
        return DomBuilderService.getSupportedContentHandler(content.type, contentHandlers).handle(content, contentHandlers, markHandlers);
    }
  }

  supports(type: string): boolean {
    return this.supportedContentTypes.includes(type);
  }

  getPriority(): number {
    return 100;
  }

  makeHeading(heading: Heading, contentHandlers: ContentHandlerInterface[], markHandlers: MarkHandlerInterface[]): HTMLHeadingElement {
    const el = this._renderer.createElement(`h${heading.attrs.level}`);
    heading.content.forEach((c) => {
      const tc = this.handle(c, contentHandlers, markHandlers);
      if (tc instanceof HTMLElement) {
        this._renderer.appendChild(el, tc);
      } else {
        el.innerHTML += tc;
      }
    });

    this._renderer.setAttribute(el, 'id', slugify(el.innerHTML, this.slugifyOptions));

    return el;
  }
}
